import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { imgix } from "@utils/imgix";

export function NextHead({ image = null, metadata = [], children = null }) {
  return (
    <Head>
      <meta key="fb:app_id" property="fb:app_id" content="111537044496" />
      <meta
        key="og:site_name"
        property="og:site_name"
        content="Lonely Planet"
      />
      <meta key="og:type" property="og:type" content="website" />
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:site" name="twitter:site" content="@lonelyplanet" />
      <meta key="twitter:site:id" name="twitter:site:id" content="15066760" />
      {image?.url && (
        <>
          <meta
            key="twitter:image"
            name="twitter:image"
            content={imgix(image?.url, { w: 600, h: 400 })}
          />
          <meta
            key="og:image"
            property="og:image"
            content={imgix(image?.url, { w: 600, h: 400 })}
          />
          <meta
            key="og:image:secure_url"
            property="og:image:secure_url"
            content={imgix(image?.url, { w: 600, h: 400 })}
          />
        </>
      )}
      {Array.isArray(metadata) &&
        metadata.map(({ tag: Tag, ...meta }) => (
          <Tag
            key={meta.name || meta.property || meta.rel || meta.href}
            {...meta}
            {...(meta.property === "og:image"
              ? { content: imgix(meta?.content, { w: 600, h: 400 }) }
              : {})}
          />
        ))}
      {children}
    </Head>
  );
}

NextHead.propTypes = {
  image: PropTypes.shape({ url: PropTypes.string }),
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      property: PropTypes.string,
      tag: PropTypes.oneOf(["link", "meta"]).isRequired,
      name: PropTypes.string,
    })
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
