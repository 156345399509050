import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { airbrakeClient } from "@utils/airbrake/browser";

import presets from "@components/ad/presets";
import { nanoid } from "nanoid";

export const GPT = memo(
  ({ path, placement, onSlotRenderEnded, onImpressionViewable, targeting }) => {
    const { slotSize, sizeMapping } = presets[placement];

    const slot = useRef(null);
    const containerRef = useRef(null);
    const containerID = useMemo(() => {
      const base = nanoid();
      return `GPT-${base}`;
    }, []);

    const build = useCallback(() => {
      if (!containerRef.current || !path || !placement) {
        return;
      }

      /** Build and draw the ad spot */
      window.googletag?.cmd?.push(() => {
        /**
         * - Init the slot we intend to build with meta info and sizing
         * - Add the pubads service
         * - Manage size mapping, where appropriate
         * - Manage targeting
         * - Set up cohesion bridge id via page-level targeting
         * - Establish event listeners
         * - Display the ad
         */
        slot.current = window.googletag
          .defineSlot(path, slotSize, containerID)
          .addService(window.googletag.pubads());
        // .setCollapseEmptyDiv(true);

        /**
         * If something went wrong with the initial setup, halt and speak up
         */
        if (!slot.current) {
          /* eslint-disable-next-line no-console */
          console.error(
            `GPT: Unable to build add slot for element with id '${containerID}'`
          );
          airbrakeClient.notify({
            error: {
              message: `GPT: Unable to build add slot for element with id '${containerID}'`,
            },
            params: { path, placement, slotSize, containerID },
            context: { component: "gpt.jsx#build" },
          });
          return;
        }

        if (sizeMapping) {
          let map = window.googletag.sizeMapping();
          sizeMapping.forEach(size => {
            map = map.addSize(size.viewport, size.slot);
          });
          map = map.build();

          slot.current.defineSizeMapping(map);
        }

        /**
         * This is slot-level targeting
         */
        Object.keys(targeting).forEach(key => {
          slot.current.setTargeting(key, targeting[key]);
        });

        /** Add page-level targeting for ad tracking (Elsewhere initial case) */
        window.googletag.pubads().setTargeting("chsn_ad_id", window.chsn_ad_id);

        /**
         * Add the event listeners which have been passed as props from
         * the `Ad` component. We're fine with them not being unsubscribed
         */
        window.googletag.pubads().addEventListener("slotRenderEnded", e => {
          onSlotRenderEnded(e);
        });
        window.googletag.pubads().addEventListener("impressionViewable", e => {
          onImpressionViewable(e);
        });

        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().setCentering(true);
        // window.googletag.pubads().enableLazyLoad({
        //   fetchMarginPercent: 100,
        //   mobileScaling: 1.5,
        // });

        window.googletag.enableServices();

        /**
         * Instruct GPT to show the ad in the slot
         */
        window.googletag.display(slot.current);
      });
    }, [
      containerID,
      onImpressionViewable,
      onSlotRenderEnded,
      path,
      placement,
      sizeMapping,
      slotSize,
      targeting,
    ]);

    const destroy = () => {
      window.googletag.cmd.push(() => {
        if (slot.current && window.googletag.destroySlots([slot.current])) {
          slot.current = null;
        }
      });
    };

    useEffect(() => {
      // wait for div ref to be ready
      if (containerRef?.current) build();

      return destroy;
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [containerRef]);

    return (
      <div
        id={containerID}
        key={containerID}
        ref={containerRef}
        style={{ minHeight: "min-content" }}
      />
    );
  }
);

GPT.propTypes = {
  onSlotRenderEnded: PropTypes.func.isRequired,
  onImpressionViewable: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  targeting: PropTypes.shape({}),
};

GPT.defaultProps = {
  targeting: {},
};
