/*
  The keys of this object correspond to the 'preset' prop on the 'Ad' component.
  The object for each key can be any set of props for the 'GPT' component.
*/
export default {
  minSlotSize: {
    slotSize: [[1, 1]],
  },
  logo: {
    slotSize: [[1, 1]],
  },
  native: {
    slotSize: ["fluid"],
  },
  inContent: {
    slotSize: [
      [300, 250],
      [1, 1],
    ],
    sizeMapping: [
      { viewport: [0, 0], slot: [] },
      {
        viewport: [320, 0],
        slot: [
          [300, 250],
          [1, 1],
        ],
      },
    ],
  },
  sidebar: {
    slotSize: [
      [300, 600],
      [300, 250],
      [160, 600],
    ],
    sizeMapping: [
      { viewport: [0, 0], slot: [] },
      { viewport: [320, 0], slot: [[300, 250]] },
      {
        viewport: [768, 0],
        slot: [
          [300, 600],
          [300, 250],
          [160, 600],
        ],
      },
    ],
  },
  railShort: {
    slotSize: [[300, 250]],
    sizeMapping: [
      { viewport: [0, 0], slot: [] },
      { viewport: [320, 0], slot: [[300, 250]] },
    ],
  },
  superzone: {
    slotSize: [
      [970, 250],
      [970, 90],
      [728, 90],
      [1, 1],
    ],
    sizeMapping: [
      { viewport: [0, 0], slot: [] },
      {
        viewport: [300, 0],
        slot: [
          [320, 50],
          [1, 1],
        ],
      },
      {
        viewport: [768, 0],
        slot: [
          [728, 90],
          [1, 1],
        ],
      },
      {
        viewport: [960, 0],
        slot: [
          [970, 250],
          [970, 90],
          [728, 90],
          [1, 1],
        ],
      },
    ],
  },
  fullWidth: {
    slotSize: [
      [970, 250],
      [970, 90],
      [728, 90],
      [300, 250],
      [320, 50],
      [1, 1],
    ],
    sizeMapping: [
      { viewport: [0, 0], slot: [] },
      {
        viewport: [300, 0],
        slot: [
          [300, 250],
          [320, 50],
          [1, 1],
        ],
      },
      {
        viewport: [768, 0],
        slot: [
          [728, 90],
          [300, 250],
          [1, 1],
        ],
      },
      {
        viewport: [960, 0],
        slot: [
          [970, 250],
          [970, 90],
          [728, 90],
          [300, 250],
          [1, 1],
        ],
      },
    ],
  },
};
