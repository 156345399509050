/**
 * Note: When debugging this work, if you have access to the LP GAM account and are logged in
 * adding `?googfc` to the url can be very helpful in unserstanding why GAM is returning
 * ads the way it is.
 */
import { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import presets from "@components/ad/presets";
import { GPT } from "./gpt";

import { AdContext } from "./context";

import styles from "./styles.module.css";

export const transformPath = path => {
  const nid = process.env.NEXT_PUBLIC_DFP_NETWORK_ID;
  const sanitizedPath = path[0] === "/" ? path.substring(1) : path;

  if (!path) {
    return `/${nid}/LonelyPlanet.com`;
  }

  return `/${nid}/LonelyPlanet.com/${sanitizedPath}`;
};

const setNaviPixel = (ad, eventType) => {
  const { creativeId, size, lineItemId, campaignId } = ad;

  const i = document.createElement("img");
  const params = {
    write_key: process.env.NEXT_PUBLIC_COHESION_WRITE_KEY,
    /**
     * This deprecated btoa() encoding feels bad, but it's evidently needed.
     * https://rvproductsuite.rvdocs.io/cohesion/navi-getting-started.html
     * https://redventures.slack.com/archives/C718VV878/p1656349084414969?thread_ts=1654535683.090419&cid=C718VV878
     */
    req: btoa(window.location.href),
    ref: btoa(document.referrer),
    adId: creativeId,
    size,
    lineItemId,
    campaignId,
    event: eventType,
  };
  const paramsStr = Object.entries(params)
    .reduce((acc, kvp) => {
      acc.push(`${kvp[0]}=${kvp[1]}`);
      return acc;
    }, [])
    .join("&");

  i.setAttribute("style", "display:none");
  i.setAttribute("src", `https://navi.cohesionapps.com/display?${paramsStr}`);
  document.body.appendChild(i);
};

const getFirstSlotSize = sizes => {
  const first = sizes[0];
  if (typeof first === "string") {
    return { width: "100%", height: 200 };
  }

  return { width: first[0], height: first[1] };
};

export function Ad({
  children = null,
  additionalTargeting = {},
  className = "",
  contextOverride = null,
  debug = false,
  native = false,
  bgColor = "bg-white",
  path,
  placement,
  ...rest
}) {
  /**
   * Ad flow isn't the easiest to trace around, so this utility
   * is made available to log all the points of interest with a single
   * prop (`debug`)
   */
  /* eslint-disable-next-line no-underscore-dangle */
  const __debug = useCallback(
    (...m) => {
      if (debug) {
        console.log(...m); /* eslint-disable-line no-console */
      }
    },
    [debug]
  );

  const [ad, setAd] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [isElsewhere, setIsElsewhere] = useState(null);

  let ctx = useContext(AdContext);
  /**
   * This is awkward but there are instances where ads may fall outside of the provider
   * for one reason or another (dynamic renders, portaling). In these cases we allow for
   * the undefined context to be overwritten by a specific prop which ideally does have access
   * to the actual context obj.
   *
   * Example: /components/_pages/articles/body/body.jsx
   */
  if (!ctx && contextOverride) {
    ctx = contextOverride;
    __debug(`Ctx overridden for: ${placement}`);
  }

  const { slotSize } = presets[placement];

  /**
   * Event fires when GAM has decisioned and returned us that data.
   */
  const handleSlotRenderEnded = useCallback(
    e => {
      setHasFetched(true);
      const { advertiserId, isEmpty } = e;

      if (isEmpty) {
        __debug("RenderEnded: Empty", { placement, e });
      }

      /**
       * At times we use the Ad slot to render a sponsor's logo on given content. In the event
       * that this load fails (but not general ad requests) we should set the no-create ad in state
       * which will allow the fallback (direct child of the `Ad` component) to display.
       */
      if (isEmpty && children) {
        __debug("RenderEnded: Empty, falling back", { placement, e });
        setAd(e);
      }

      if (!isEmpty && !hasFetched) {
        setAd(e);

        /** Check if we're served an Elsewhere advert */
        const ELSEWHERE_COMPANY_ID = 5200917308;
        if (advertiserId === ELSEWHERE_COMPANY_ID) {
          setIsElsewhere(true);
        }

        /** Call NAVI */
        setNaviPixel(e, "tracked");

        __debug("RenderEnded: Returned", { placement, e });
      }
    },
    [children, hasFetched, __debug, placement]
  );

  /**
   * Event fires when ad has been loaded and viewable on screen for > 1s, as
   * tracked by the GPT script
   */
  const handleImpressionViewable = () => {
    if (ad) {
      setNaviPixel(ad, "viewed");
    }
    __debug("ImpressionViewable:", { placement, ad });
  };

  if (children && hasFetched && ad?.isEmpty) {
    return children;
  }

  const targeting = { ...ctx?.targeting, ...additionalTargeting };

  return (
    <div
      className={cn(
        {
          hidden:
            (ad === null || ad?.isEmpty) &&
            process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV === "production",
        },
        styles["gpt-ad"],
        // TODO: troubleshoot why ad.isEmpty is false for empty ads
        bgColor,
        "pb-3",
        className
      )}
    >
      {ad && !ad?.isEmpty && !native && !isElsewhere && (
        <p className="p-3 text-xs leading-none tracking-wide text-center uppercase text-black-400">
          Advertisement
        </p>
      )}
      {/**
       * We don't want to canibalize ad inventory in development unless we absolutely need to
       * so in development - unless we are debugging - we want to show an appropriately sized
       * placeholder rather than an actual ad.
       *
       * On production, communicate with GAM via GPT as expected.
       */}
      {ctx?.GPTReady &&
        (process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV === "production" ||
          (process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV === "development" &&
            debug)) && (
          <GPT
            placement={placement}
            path={transformPath(path)}
            onSlotRenderEnded={handleSlotRenderEnded}
            onImpressionViewable={handleImpressionViewable}
            targeting={targeting}
            {...rest}
          />
        )}
      {process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_ENV === "development" &&
        !debug && (
          <div
            className="p-4 mx-auto text-white bg-blue-400"
            style={getFirstSlotSize(slotSize)}
          >
            <ul className="text-sm">
              <li>placement: {placement}</li>
              <li>path: {path}</li>
              <li>
                possible size:{" "}
                {slotSize.map(size => `[${size[0]}, ${size[1]}], `)}
              </li>
              <li>
                targeting:{" "}
                <pre className="font-sans overflow-hidden">
                  {JSON.stringify(targeting, null, 2)}
                </pre>
              </li>
            </ul>
          </div>
        )}
    </div>
  );
}

Ad.propTypes = {
  additionalTargeting: PropTypes.shape({}),
  className: PropTypes.string,
  contextOverride: PropTypes.shape({}),
  debug: PropTypes.bool,
  native: PropTypes.bool,
  path: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  white: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  bgColor: PropTypes.string,
};
